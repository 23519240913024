import React from 'react';
import theme from '@style';
import i18n from '@i18n';
import {
  teamReportImage,
  commissionDetailImage,
  invitationRulesImage,
  // customerServiceImage,
} from '../proxy.variable';
import {View} from 'react-native';
import {SubItem} from '../basic-components';
import {goAgentService, goTo, toAgentApply} from '@/utils';

export interface SubEntryProps {
  userId?: number;
  agentLevel?: number;
}

const SubEntry: React.FC<SubEntryProps> = ({userId, agentLevel}) => {
  const list = [
    {icon: teamReportImage, title: i18n.t('proxy.home.team-report')},
    {
      icon: commissionDetailImage,
      title: i18n.t('proxy.home.commission-detail'),
    },
    {icon: invitationRulesImage, title: i18n.t('proxy.home.invitation-rules')},
    // {
    //   icon: customerServiceImage,
    //   title: i18n.t('proxy.home.agent-line-customer-service'),
    // },
  ];
  const onPress = (index: number) => {
    const handler = [
      () => goTo('ProxyTeamReport'),
      () => {
        if (userId != null) {
          goTo('ProxyCommissionDetail', {userId, agentLevel});
        }
      },
      () => {
        toAgentApply();
      },
      () => goAgentService(),
    ];
    handler[index]?.();
  };
  return (
    <View
      style={[
        theme.background.white,
        theme.margin.lrl,
        {
          marginTop: theme.paddingSize.l,
          padding: theme.paddingSize.l,
          borderRadius: theme.borderRadiusSize.m,
          marginBottom: 50,
        },
      ]}>
      {list.map((item, index) => {
        return <SubItem {...item} key={index} onPress={() => onPress(index)} />;
      })}
    </View>
  );
};

export default SubEntry;
