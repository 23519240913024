import React, {useEffect, useState} from 'react';
import RenderHtml from 'react-native-render-html';
import {useInnerStyle} from './notification.hooks';
import DetailNavTitle from '@businessComponents/detail-nav-title';
import {goBack} from '@utils';
import {ScrollView, View} from 'react-native';
import theme from '@/style';
import Text from '@basicComponents/text';
import dayjs from 'dayjs';
import {NotificationItem} from './notification.service';
import globalStore from '@/services/global.state';
import {useTranslation} from 'react-i18next';
import {LazyImageLGBackground} from '@basicComponents/image';

const NotificationDetail = () => {
  const {i18n} = useTranslation();
  const [item, setItem] = useState<NotificationItem>();

  useEffect(() => {
    globalStore.asyncGetItem('notification-detail').then(_item => {
      if (_item) {
        setItem(JSON.parse(_item) as NotificationItem);
      }
    });
  }, []);

  const {
    size: {screenHeight, screenWidth},
    detailStyle,
  } = useInnerStyle();
  const source = {
    html: item?.messageContent || '',
  };
  const htmlStyle = {
    fontSize: theme.fontSize.m,
    lineHeight: theme.fontSize.m * 1.4,
    fontFamily: 'Inter',
    color: theme.fontColor.accent,
  };
  const tagsStyles = {
    p: {
      marginTop: 0,
    },
    img: {
      width: screenWidth - (theme.paddingSize.xxl + theme.paddingSize.l) * 2,
    },
  };
  return (
    <LazyImageLGBackground
      style={[{height: screenHeight}, theme.background.lightGrey]}>
      <DetailNavTitle
        title={i18n.t('notification.title')}
        hideAmount
        hideServer
        onBack={goBack}
      />
      <ScrollView
        style={[
          theme.flex.flex1,
          theme.background.white,
          theme.margin.lrl,
          theme.margin.tbxxl,
          theme.padding.xxl,
          theme.borderRadius.m,
        ]}>
        <View style={[theme.flex.center]}>
          <Text
            fontSize={theme.fontSize.xl}
            main
            blod
            style={[theme.margin.btmxxs, detailStyle.title]}>
            {item?.messageTitle}
          </Text>
        </View>
        <View style={[theme.flex.center, theme.margin.btms]}>
          <Text fontSize={theme.fontSize.s} secAccent>
            {dayjs(item?.sendTime).format('YYYY.MM.DD')}
          </Text>
        </View>

        <RenderHtml
          source={source}
          baseStyle={htmlStyle}
          tagsStyles={tagsStyles}
        />
      </ScrollView>
    </LazyImageLGBackground>
  );
};

export default NotificationDetail;
