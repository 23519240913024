import {http} from '@/utils';
import {PageParams, PageResponse} from '@types';

export interface PromotionListItem {
  //活动内容
  activityContent?: string;
  activityStartTime?: number;
  activityEndTime?: number;
  activityIcon?: string;
  // 标题
  activityTitle: string;
  activitySubTitle: string;
  // 跳转url，后台设定
  activityUrl: string;
  activityType?: 'signin' | 'other';
  id: number;
}

export function getPromotionList(pageNo: number) {
  return http.post<PageParams, PageResponse<PromotionListItem>>(
    'app/sysActivity/getList',
    {
      pageNo,
    },
  );
}

export function getPromotionDetail(id: number) {
  return http.post<{}, PromotionListItem>(
    `app/sysActivity/getSysActivity/${id}`,
  );
}
