import React, {useState} from 'react';
import {
  ScrollView,
  Image,
  TouchableOpacity,
  Linking,
  View,
  StyleSheet,
} from 'react-native';
import {PopListItem} from '../../app.service';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';

export interface PopListProps {
  popConfigList: PopListItem[];
}

const VipClubList: React.FC<PopListProps> = ({popConfigList}) => {
  const {screenWidth} = useScreenSize();

  // 新增：状态来追踪当前索引
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  // 新增：处理滚动事件，更新当前索引
  const handleScroll = (event: any) => {
    const contentOffsetX = event.nativeEvent.contentOffset.x;
    const index = Math.floor(contentOffsetX / (screenWidth / 1.05));
    setCurrentIndex(index); // 更新当前索引
  };
  return (
    <View
      style={{
        alignItems: 'center',
        height: screenWidth / 0.79 + 30,
      }}>
      <ScrollView
        horizontal
        showsHorizontalScrollIndicator={false}
        pagingEnabled
        onScroll={handleScroll} // 新增：处理滚动事件
        style={[{width: screenWidth / 1.05}]}>
        {popConfigList.map((item, index) => (
          <TouchableOpacity
            key={index}
            onPress={() => {
              if (item.popUrl) {
                Linking.openURL(item.popUrl);
              }
            }}>
            <Image
              source={{uri: item.popImg}}
              style={{
                width: screenWidth / 1.05,
                height: screenWidth / 0.79,
                zIndex: 2,
                resizeMode: 'contain',
                borderRadius: screenWidth / 22,
              }}
            />
          </TouchableOpacity>
        ))}
      </ScrollView>

      {/* 新增：指示点容器 */}
      <View
        style={[
          styles.indicatorContainer,
          {width: screenWidth / 1.05, height: 20},
        ]}>
        {popConfigList.map((_, index) => (
          // 新增：指示点
          <View
            key={index}
            style={[
              styles.indicator,
              currentIndex === index
                ? styles.activeIndicator
                : styles.inactiveIndicator,
            ]}
          />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  indicatorContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 10,
  },
  indicator: {
    width: 8,
    height: 8,
    borderRadius: 4,
    margin: 5,
  },
  activeIndicator: {
    backgroundColor: '#00BB8F', // 当前激活的指示点颜色
  },
  inactiveIndicator: {
    backgroundColor: '#acacad', // 非激活的指示点颜色
  },
});

export default VipClubList;
