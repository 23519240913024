import DetailNavTitle from '@/components/business/detail-nav-title';
import theme from '@/style';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {FlatList, ListRenderItemInfo, RefreshControl, View} from 'react-native';
import Text from '@basicComponents/text';
import {useInnerStyle} from './notification.hooks';
import Tag from '@/components/basic/tag';
import {
  LazyImageLGBackground,
  LazyImageBackground,
} from '@basicComponents/image';
import NoData from '@/components/basic/error-pages/no-data';
import {
  NotificationItem,
  postSystemMessageList,
  postUserMessageList,
} from './notification.service';
import dayjs from 'dayjs';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import globalStore from '@/services/global.state';
import {MessageCountInfo} from '@/services/global.service';
import {goBack, goTo, scaleSize} from '@/utils';
import {useSharedValue} from 'react-native-reanimated';
import {PageResponse} from '@/types';
import {NoMoreData} from '@/components/basic/default-page';
import {useTranslation} from 'react-i18next';

const NotificationPage = () => {
  const {i18n} = useTranslation();
  const [refreshing, setRefreshing] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [listData, setListData] = useState<NotificationItem[]>([]);
  const pageNo = useRef(1);
  const totalPage = useRef(1);
  const [countInfo, setCountInfo] = useState<MessageCountInfo>({
    messageTotalCount: 0,
    sysMessageCount: 0,
    sysUserMessageCount: 0,
  });
  const left = useSharedValue(0);
  const {
    size: {
      listItemImageSize,
      screenHeight,
      screenWidth,
      indicatorWidth,
      tabCount,
    },
    tabStyle,
  } = useInnerStyle();

  const lastPageIndex = useRef(0);
  const refreshPageInfo = useCallback(async () => {
    if (
      lastPageIndex.current === tabIndex &&
      pageNo.current > totalPage.current
    ) {
      return;
    }
    lastPageIndex.current = tabIndex;
    let pageInfo: PageResponse<NotificationItem> = {
      content: [],
      totalPages: 0,
      totalSize: 0,
    };
    if (tabIndex === 0) {
      pageInfo = await postUserMessageList(pageNo.current);
    } else {
      pageInfo = await postSystemMessageList(pageNo.current);
    }
    if (pageNo.current === 1) {
      setListData(pageInfo.content);
    } else {
      setListData([...listData, ...pageInfo.content]);
    }

    totalPage.current = pageInfo.totalPages;
  }, [listData, tabIndex]);

  const refreshNotification = useCallback(
    async (loading = true) => {
      loading && globalStore.globalLoading.next(true);
      try {
        pageNo.current = 1;
        await refreshPageInfo();
      } finally {
        globalStore.globalLoading.next(false);
        setRefreshing(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tabIndex],
  );

  const refreshNextPage = () => {
    // 这个情况是加载之前的或者只有一页，不让进行下一页
    if (totalPage.current === 1) {
      return;
    }
    pageNo.current++;
    globalStore.globalLoading.next(true);
    refreshPageInfo().finally(() => globalStore.globalLoading.next(false));
  };

  useEffect(() => {
    const sub = globalStore.notificationSubject.subscribe(_countInfo => {
      setCountInfo(_countInfo);
      refreshNotification(false);
    });
    return () => {
      sub.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refreshNotification();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex]);

  useEffect(() => {
    left.value =
      (screenWidth / tabCount - indicatorWidth) / 2 +
      (tabIndex * screenWidth) / tabCount;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabIndex, screenWidth, tabCount, indicatorWidth]);

  const handleChange = (index: number) => {
    setTabIndex(index);
  };

  const toDetail = (item: NotificationItem) => {
    globalStore.readNotification.next({
      messageId: item.id,
      messageType: tabIndex === 0 ? 2 : 1,
    });
    globalStore.asyncSetItem('notification-detail', JSON.stringify(item));
    goTo('NotificationDetail');
  };

  const renderItem = ({item}: ListRenderItemInfo<NotificationItem>) => {
    return (
      <NativeTouchableOpacity
        style={[theme.margin.btml]}
        onPress={() => toDetail(item)}>
        <View
          style={[
            theme.padding.l,
            theme.background.white,
            theme.borderRadius.l,
          ]}>
          <View key={item.id} style={[theme.flex.row, theme.flex.centerByCol]}>
            <LazyImageBackground
              radius={listItemImageSize}
              width={listItemImageSize}
              height={listItemImageSize}
              imageUrl={item.messageIcon}
              style={[theme.margin.rightl]}
            />
            <View
              style={[theme.flex.col, theme.flex.alignStart, theme.flex.flex1]}>
              <Text
                main={item.ifRead === 0}
                secAccent={item.ifRead !== 0}
                fontSize={theme.fontSize.l}
                style={[theme.padding.btmxxs]}>
                {item.messageTitle}
              </Text>
              <Text secAccent fontSize={theme.fontSize.s}>
                {dayjs(item.sendTime).format('YYYY.MM.DD')}
              </Text>
            </View>
          </View>
          <Text
            style={[theme.margin.topl]}
            accent={item.ifRead === 0}
            secAccent={item.ifRead !== 0}
            fontSize={theme.fontSize.s}
            ellipsizeMode="tail"
            numberOfLines={2}>
            {item.messageContent?.replace(/<[^<>]+>/g, '')}
          </Text>
        </View>
      </NativeTouchableOpacity>
    );
  };
  return (
    <LazyImageLGBackground style={[{height: screenHeight}, theme.flex.col]}>
      <DetailNavTitle
        title={i18n.t('notification.title')}
        hideAmount
        hideServer
        onBack={goBack}
      />
      <View
        style={[
          theme.flex.row,
          theme.position.rel,
          theme.margin.lrl,
          theme.flex.centerByCol,
          theme.padding.lrl,
          {
            backgroundColor: '#ffffff33',
            height: scaleSize(42),
            borderRadius: scaleSize(21),
          },
        ]}>
        <NativeTouchableOpacity
          style={[theme.flex.flex1, theme.position.rel, theme.fill.fillH]}
          onPress={() => handleChange(0)}>
          <View
            style={[
              theme.flex.flex1,
              theme.flex.center,
              theme.margin.tbxs,
              {
                backgroundColor: tabIndex === 0 ? '#00000033' : '#00000000',
                borderRadius: scaleSize(21),
              },
            ]}>
            <Text
              white={tabIndex === 0}
              blod={tabIndex === 0}
              fontSize={theme.fontSize.m}>
              {i18n.t('notification.announcements')}
            </Text>
          </View>
          {countInfo.sysUserMessageCount > 0 ? (
            <Tag
              content={countInfo.sysUserMessageCount}
              style={[theme.position.abs, tabStyle.tag]}
              backgroundColor={theme.backgroundColor.accent}
            />
          ) : null}
        </NativeTouchableOpacity>
        <NativeTouchableOpacity
          style={[theme.flex.flex1, theme.position.rel, theme.fill.fillH]}
          onPress={() => handleChange(1)}>
          <View
            style={[
              theme.flex.flex1,
              theme.flex.center,
              theme.margin.tbxs,
              {
                backgroundColor: tabIndex === 1 ? '#00000033' : '#00000000',
                borderRadius: scaleSize(21),
              },
            ]}>
            <Text
              white={tabIndex === 1}
              blod={tabIndex === 1}
              fontSize={theme.fontSize.m}>
              {i18n.t('notification.systemMessage')}
            </Text>
          </View>
          {countInfo.sysMessageCount > 0 ? (
            <Tag
              content={countInfo.sysMessageCount}
              style={[theme.position.abs, tabStyle.tag]}
              backgroundColor={theme.backgroundColor.accent}
            />
          ) : null}
        </NativeTouchableOpacity>
      </View>

      <FlatList
        style={[theme.flex.flex1]}
        refreshControl={
          <RefreshControl
            refreshing={refreshing}
            onRefresh={() => {
              setRefreshing(true);
              refreshNotification(false);
            }}
          />
        }
        contentContainerStyle={[theme.padding.l]}
        ListEmptyComponent={
          <View style={[theme.borderRadius.l]}>
            <NoData />
          </View>
        }
        onEndReached={refreshNextPage}
        data={listData}
        renderItem={renderItem}
        ListFooterComponent={
          listData &&
          listData.length > 0 &&
          pageNo.current >= totalPage.current ? (
            <NoMoreData />
          ) : undefined
        }
      />
    </LazyImageLGBackground>
  );
};

export default NotificationPage;
